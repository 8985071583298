export async function useShowAds() {
    const pageStore = usePageStore();
    const showAds = ref(pageStore.getShowAds);
    let initialized = false;

    function getShowAds() {
        if (!initialized) {
            checkURLParams();
            initialized = true;
        }
        if (showAds.value === undefined) {
            console.trace();
        }
        return showAds;
    }

    function checkURLParams() {
        try {
            const currentUrl = useRequestURL();
            const urlParamsIsSet = currentUrl?.searchParams?.get("ads") && (currentUrl?.searchParams.get("ads") === "false" || currentUrl?.searchParams.get("ads") === "true");
                if (urlParamsIsSet) {
                    const urlParam = currentUrl.searchParams.get("ads");
                    if (urlParam !== undefined) {
                        switch (urlParam) {
                            case "true":
                                showAds.value = true;
                                break;
                            case "false":
                                showAds.value = false;
                                break;
                        }
                    }
                }         
        } catch (error) {
            console.log("unable to retrieve currentUrl");
        }
    }

    return {getShowAds};
}